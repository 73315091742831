<template>
  <div>
    <div style="display:flex;justify-content:space-between;margin:10px 0">
      <a-form layout="inline">
        <!--        <a-form-item label="司机姓名">-->
        <!--          <a-input v-model:value="searchForm.name"/>-->
        <!--        </a-form-item>-->
        <!--        <a-form-item>-->
        <!--          <a-button :loading="loading" @click="search">搜索</a-button>-->
        <!--        </a-form-item>-->
      </a-form>
      <a-button @click="addShow = true">+绑定会员</a-button>
    </div>
    <a-table size="small" :columns="columns" :row-key="record => record.id" :data-source="listData" bordered
      :pagination="pagination" :loading="loading" @change="handleTableChange">
      <template #operation="{ record }">
        <div style="display:flex;">
          <a @click="onSetMainAccount(record)" v-if="record.isMain.value === 0">设置主账号</a>
          <a-divider v-if="record.isMain.value === 0" type="vertical" />
          <a-popconfirm title="确认删除" ok-text="确认" cancel-text="取消" @confirm="detailData(record)">
            <a style="color:#f00">删除</a>
          </a-popconfirm>
        </div>
      </template>
      <template #objectType="{ record }">
        {{ record.objectType === 1 ? '个人' : '' }}
        {{ record.objectType === 2 ? '企业' : '' }}
      </template>
    </a-table>
    <a-modal :confirmLoading="confirmLoading" :width="750" v-model:visible="addShow" title="绑定会员" footer=""
      @cancel="onNoAddForm" destroyOnClose>
      <a-form layout="vertical" ref="formRef" :model="addForm" :rules="rules">
        <a-form-item label="会员名称" name="memberAlias">
          <a-select v-model:value="addForm.memberAlias" placeholder="请输入会员别名" :filter-option="false"
            :show-arrow="false" @search="membersSearch" @select="membersSelect" show-search>
            <a-select-option v-for="item in membersList" :key="item.bizUserId" :value="item.bizUserId">
              <span>{{ item.memberAlias }}</span>
              <span style="float:right;">{{ item.phone }}</span>
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="主账号" :name="['isMain', 'value']">
          <a-select v-model:value="addForm.isMain.value" :options="$store.state.enumAll.BooleanFlagEnum"
            @change="booleanChange" />
        </a-form-item>

      </a-form>
      <div style="display:flex;justify-content: flex-end;margin-top:15px">
        <a-button size="large" style="margin-right:15px" @click="addShow = false">取消</a-button>
        <a-button size="large" class="searchLoading-button" @click="onBind">新增</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { reactive, toRefs, ref } from 'vue'
import { getMemberRel, getMemberList, addMemberRel, setMainAccount, deleteAccount } from '@/api/carrier/cloudFlash'
import { message } from 'ant-design-vue'
export default {
  components: {

  },
  props: { carrierId: String, carrierNatureType: Number },
  setup (props) {
    const formRef = ref()
    const state = reactive({
      carrierId: props.carrierId,
      carrierNatureType: props.carrierNatureType,
      loading: false,
      confirmLoading: false,
      addShow: false,
      searchForm: {
        name: ''
      },
      addForm: {
        bizUserId: '',
        isMain: {}
      },
      listData: [],
      membersList: [],
      columns: [
        {
          title: '会员别名',
          dataIndex: 'memberAlias',
          align: 'center'
        },
        {
          title: '会员账号',
          dataIndex: 'bizUserId',
          align: 'center'
        },
        {
          title: '是否主账号',
          dataIndex: 'isMain.label',
          align: 'center'
        },
        {
          title: '会员类型',
          dataIndex: 'memberType.label',
          align: 'center',
          // slots: {
          //   customRender: 'objectType'
          // }
        },
        {
          title: '',
          dataIndex: 'operation',
          align: 'center',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      rules: {
        memberAlias: [{ required: true, message: '请填写会员名称',trigger: 'change', type: 'string'  }],
        isMain: { value: [{ required: true, message: '请选择是否为主账号', trigger: 'change', type: 'number' }] }
      }
    })
    const loadData = () => {
      state.loading = true
      getMemberRel({ objectId: state.carrierId })
        .then(res => {
          if (res.code === 10000) {
            state.listData = res.data
            state.pagination.total = res.data.length
            state.pagination.pageSize = res.data.length
          }
        }).catch(err => { console.log(err) })
        .finally(() => { state.loading = false })
    }
    const search = () => {
      state.pagination.current = 1
      loadData()
    }
    const membersSearch = async val => {
      if (val.length > 1) {
        if (state.timeOut !== null) {
          clearTimeout(state.timeOut)
        }
        state.timeOut = setTimeout(() => {
          getMemberList({ memberAlias: val, size: 100 }).then((res) => {
            if (res.code === 10000) {
              state.membersList = res.data.records
            } else {
              state.membersList = []
            }
          })
        }, 500)
      } else {
        state.membersList = []
      }
    }
    const membersSelect = async (val, option) => {
      state.addForm.bizUserId = val
    }
    const onBind = () => {
      formRef.value.validate().then(() => {
        state.addForm.objectId = state.carrierId
        state.addForm.objectType = state.carrierNatureType
        state.confirmLoading = true
        addMemberRel({ ...state.addForm }).then((res) => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.addForm = {
              isMain: {}
            }
            state.addShow = false
            loadData()
          }
        }).finally(() => { state.confirmLoading = false })
      })
    }
    const booleanChange = (e, v) => {
      state.addForm.isMain = v
    }
    const onSetMainAccount = (e) => {
      setMainAccount({ id: e.id }).then((res) => {
        if (res.code === 10000) {
          message.success(res.msg)
          loadData()
        }
      })
    }
    const detailData = item => {
      deleteAccount({ relId: item.id }).then(res => {
        if (res.code === 10000) {
          message.success('账号删除成功')
          loadData()
        }
      })
    }
    return {
      ...toRefs(state),
      formRef,
      loadData,
      detailData,
      membersSearch,
      membersSelect,
      booleanChange,
      onSetMainAccount,
      onBind,
      search
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
@import '../common.less';
</style>
